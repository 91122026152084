<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-4">
            <h5 class="ms-3 mb-0 text-secondary">
                Subscribers
            </h5>
            <DashboardLink :to="{ name: 'subscriber', params: { id: 'new' } }"
                           class="btn btn-outline-dark ms-3">
                Add subscriber
            </DashboardLink>
        </div>
<!--        <div class="table-responsive scrollbar">-->
<!--            <table class="table table-hover condensed">-->
<!--                <thead>-->
<!--                <tr>-->
<!--                    <th scope="col">Email</th>-->
<!--                    <th scope="col">Name</th>-->
<!--                    <th scope="col">Phone</th>-->
<!--                    <th scope="col">Source</th>-->
<!--                    <th scope="col">Status</th>-->
<!--                    <th scope="col">Created at</th>-->
<!--                </tr>-->
<!--                </thead>-->
<!--                <tbody class="border-top-0">-->
<!--                <tr v-for="subscriber in subscribers" @click="viewSubscriber(subscriber)" class="cursor&#45;&#45;pointer">-->
<!--                    <td>{{ subscriber.email }}</td>-->
<!--                    <td>{{ subscriber.name }}</td>-->
<!--                    <td>{{ subscriber.phone_dial_code }} {{ subscriber.phone }}</td>-->
<!--                    <td>{{ subscriber.source }}</td>-->
<!--                    <td>{{ subscriber.status }}</td>-->
<!--                    <td>{{ subscriber.created_at | date }}</td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--            </table>-->
<!--        </div>-->
        <AppTable :columns="columns"
                  :filters="filters"
                  @on-filter-change="onFilterChange">
            <tr v-for="subscriber in subscribers" @click="viewSubscriber(subscriber)" class="cursor--pointer">
                <td>{{ subscriber.email }}</td>
                <td>{{ subscriber.name }}</td>
                <td>{{ subscriber.phone_dial_code }} {{ subscriber.phone }}</td>
                <td>{{ subscriber.source }}</td>
<!--                <td>{{ subscriber.status }}</td>-->
                <td>{{ subscriber.created_at | date }}</td>
            </tr>
        </AppTable>
        <AppPagination @first="first"
                       @previous="previous"
                       @next="next"
                       @last="last"
                       @update-limit="updateLimit"
                       :current-page="currentPage"
                       :last-page="lastPage"
                       :limit="limit"
                       :isFirstPage="isFirstPage"
                       :isLastPage="isLastPage"
                       :paginationOptions="paginationOptions"/>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { formatDate } from '@/filters/date.filter';
import DashboardLink from '@/components/navigation/DashboardLink';
import AppPagination from '@/components/AppPagination';
import AppTable from '@/components/form/AppTable';
import { debounce } from 'lodash';

export default {
  components: {
    DashboardLink,
    AppPagination,
    AppTable,
  },
  filters: {
    date: formatDate,
  },
  computed: {
    ...mapState({
      limit: (state) => state.subscribers.limit,
      currentPage: (state) => state.subscribers.currentPage,
      lastPage: (state) => state.subscribers.lastPage,
      paginationOptions: (state) => state.subscribers.paginationOptions,
      filters: (state) => state.subscribers.filters,
      columns() {
        return [
          {
            label: 'Email',
            field: 'email',
            filterOptions: {
              enabled: true,
            },
          },
          {
            label: 'Name',
            field: 'name',
            filterOptions: {
              enabled: true,
            },
          },
          {
            label: 'Phone',
            field: 'phone',
            filterOptions: {
              enabled: true,
            },
          },
          {
            label: 'Source',
            field: 'source',
            filterOptions: {
              enabled: true,
            },
          }, /*
          {
            label: 'Status',
            field: 'status',
            filterOptions: {
              enabled: true,
            },
          }, */
          {
            label: 'Created at',
            field: 'created_at',
            filterOptions: {
              enabled: false,
              date: true,
            },
          },
        ];
      },
    }),
    ...mapGetters({
      subscribers: 'subscribers/all',
    }),
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.lastPage;
    },
  },
  watch: {
    '$route.params.section'() {
      this.getSubscribers();
    },
  },
  mounted() {
    this.getSubscribers();
  },
  methods: {
    onFilterChange: debounce(async function changeFilters(filters) {
      console.log(filters);
      await this.$store.commit('subscribers/filters', filters);
      this.getSubscribers();
    }, 300),
    getSubscribers() {
      this.$store.dispatch('subscribers/getSubscribers');
    },
    viewSubscriber(subscriber) {
      this.$router.push({
        name: 'subscriber',
        params: { id: subscriber.id },
      });
    },
    updateLimit(val) {
      this.$store.commit('subscribers/currentPage', 1);
      this.$store.commit('subscribers/limit', val.limit);
      this.getSubscribers();
    },
    first() {
      this.$store.commit('subscribers/currentPage', 1);
      this.getSubscribers();
    },
    previous() {
      const page = this.currentPage - 1;
      this.$store.commit('subscribers/currentPage', page);
      this.getSubscribers();
    },
    next() {
      const page = this.currentPage + 1;
      this.$store.commit('subscribers/currentPage', page);
      this.getSubscribers();
    },
    last() {
      this.$store.commit('subscribers/currentPage', this.lastPage);
      this.getSubscribers();
    },
  },
};
</script>
