export class SubscriberStatus {
  static ACTIVE = 'ACTIVE';

  static INACTIVE = 'INACTIVE';

  static PENDING_CANCELLATION = 'PENDING_CANCELLATION';

  static labels = {
    [this.ACTIVE]: 'Active',
    [this.INACTIVE]: 'Inactive',
    [this.PENDING_CANCELLATION]: 'Pending Cancellation',
  };

  static options = () => Object.keys(this.labels)
    .map((key) => ({
      id: key,
      name: this.labels[key],
    }));
}
