<template>
    <div class="table-responsive scrollbar">
        <table class="table table-hover condensed">
            <thead>
            <tr>
                <th scope="col" v-for="column in columns">
                    <strong>{{ column.label }}</strong>
                    <AppTableFilter :column="column"
                                    :filters="filters"
                                    @on-filter-change="onFilterChange"/>
                </th>
            </tr>
            </thead>
            <tbody class="border-top-0">
            <slot></slot>
            </tbody>
        </table>
    </div>
</template>

<script>
import AppTableFilter from '@/components/form/AppTableFilter';

export default {
  components: {
    AppTableFilter,
  },
  props: {
    columns: {},
    filters: {},
  },
  methods: {
    onFilterChange(filters) {
      this.$emit('on-filter-change', filters);
    },
  },
};
</script>
