<template>
    <div>
        <AppSelect v-for="(label, key) in product.services"
                   :key="key"
                   :label="label"
                   :value="serviceStatus(key)"
                   :field="key"
                   :options="subscriberStatuses"
                   @input="updateProp({ [key]: $event ? $event.id : null })"
                   class="col-6 mb-2"/>
    </div>
</template>

<script>
import AppSelect from '@/components/form/AppSelect';
import { mapGetters } from 'vuex';
import { SubscriberStatus } from '@/store/models/subscriber-status';

export default {
  components: {
    AppSelect,
  },
  props: ['subscriber'],
  computed: {
    ...mapGetters({
      product: 'products/product',
    }),
    subscriberStatuses() {
      return SubscriberStatus.options();
    },
  },
  methods: {
    updateProp(props) {
      this.$emit('update', { ...this.subscriber.services, ...props });
    },
    serviceStatus(key) {
      if (!this.subscriber.services) {
        return null;
      }

      return this.subscriberStatuses.find((status) => status.id === this.subscriber.services[key]);
    },
  },
};
</script>
