var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.label)?_c('label',{class:_vm.labelClasses,attrs:{"for":_vm.field}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:_vm.containerClasses},[_c('DatePicker',{class:[
                        _vm.errors && _vm.errors.has(_vm.field) ? 'is-invalid' : '',
                        ..._vm.inputClasses,
                        ],attrs:{"disabled":_vm.disabled,"inline":_vm.inline,"id":_vm.field,"placeholder":_vm.placeholder,"autocomplete":"off","lang":{
                        formatLocale: {
                            firstDayOfWeek: 1,
                        },
                    },"range":_vm.mode === 'range',"format":_vm.format,"type":_vm.type,"value":_vm.inputValue},on:{"change":_vm.outputValue}}),_vm._t("default"),(_vm.errors)?_c('AppError',{attrs:{"errors":_vm.errors,"field":_vm.field}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }