<template>
    <div v-if="subscriber">
        <div class="row mb-3">
            <h6>General</h6>
            <AppInput label="Email"
                      :value="subscriber.email"
                      @input="updateProp({ email: $event })"
                      :errors="errors"
                      field="email"
                      placeholder="Email"
                      class="col-6"/>
            <AppInput label="Name"
                      :value="subscriber.name"
                      @input="updateProp({ name: $event })"
                      :errors="errors"
                      field="name"
                      placeholder="Name"
                      class="col-6"/>
        </div>
        <div class="row mb-3">
            <AppInput label="Phone dial code"
                      :value="subscriber.phone_dial_code"
                      @input="updateProp({ phone_dial_code: $event })"
                      :errors="errors"
                      field="phone_dial_code"
                      placeholder="Phone dial code"
                      class="col-6"/>
            <AppInput label="Phone"
                      :value="subscriber.phone"
                      @input="updateProp({ phone: $event })"
                      :errors="errors"
                      field="phone"
                      placeholder="Phone"
                      class="col-6"/>
        </div>
        <div class="row mb-4">
            <AppInput label="Password"
                      :value="subscriber.password"
                      @input="updateProp({ password: $event })"
                      :errors="errors"
                      field="password"
                      type="password"
                      placeholder="Password"
                      class="col-6"/>
        </div>
        <div class="row mb-4">
            <AppSelect label="Status (legacy)"
                       :value="subscriberStatus"
                       field="status"
                       :options="subscriberStatuses"
                       @input="updateProp({ status: $event.id })"
                       class="col-6"/>
        </div>
        <div class="row mb-5">
            <h6>Services</h6>
            <SubscriberServices :subscriber="subscriber"
                                @update="updateProp({services: $event})"/>
        </div>
        <fieldset class="text-end" :disabled="loading">
            <button class="btn btn-primary" @click="save">Save</button>
        </fieldset>
    </div>
</template>

<script>
import AppInput from '@/components/form/AppInput';
import Errors from '@/utils/errors';
import { mapGetters } from 'vuex';
import NotificationService from '@/services/notification.service';
import SubscriberServices from '@/components/subscribers/SubscriberServices';
import AppSelect from '@/components/form/AppSelect.vue';
import { SubscriberStatus } from '@/store/models/subscriber-status';

export default {
  components: {
    AppSelect,
    AppInput,
    SubscriberServices,
  },
  data() {
    return {
      errors: new Errors(),
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      subscriber: 'subscribers/formSubscriber',
    }),
    isNew() {
      return this.$route.params.id === 'new';
    },
    subscriberStatuses() {
      return SubscriberStatus.options();
    },
    subscriberStatus() {
      return { id: this.subscriber.status, name: SubscriberStatus.labels[this.subscriber.status] };
    },
  },
  mounted() {
    this.initSubscriber();
  },
  beforeDestroy() {
    this.$store.dispatch('subscribers/discardNewSubscriber');
  },
  watch: {
    '$route.params.id'() {
      this.initSubscriber();
    },
  },
  methods: {
    initSubscriber() {
      if (this.isNew) {
        this.$store.dispatch('subscribers/newSubscriber');
      } else {
        this.$store.commit('subscribers/formSubscriberId', this.$route.params.id);
        this.getSubscriber();
      }
    },
    getSubscriber() {
      this.$store.dispatch('subscribers/getSubscriber', this.$route.params.id);
    },
    updateProp(props) {
      this.$store.dispatch('subscribers/subscriberProps', {
        props,
        subscriberId: this.subscriber.id,
      });
    },
    async save() {
      this.loading = true;
      this.errors.clear();

      try {
        const res = await this.$store.dispatch(this.isNew ? 'subscribers/createSubscriber' : 'subscribers/updateSubscriber', this.subscriber);
        NotificationService.success(res.message);
        await this.$store.dispatch('subscribers/getSubscriber', res.subscriber_id);
        await this.$store.dispatch('subscribers/newSubscriber');
      } catch (err) {
        this.errors.set(err.response.data.errors);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
