<template>
    <div class="d-flex flex-row align-items-center justify-content-end">
        <AppSelect :value="{limit}"
                   :options="paginationOptions"
                   field="limit"
                   @input="$emit('update-limit', $event)"
                   placeholder=""
                   track-by="limit"
                   label-field="limit"
                   label="Rows per page"
                   class="multiselect--sm multiselect--width-sm multiselect--horizontal"
        />
        <small class="ps-5 pe-4">{{ this.currentPage }} of {{ this.lastPage }}</small>
        <button class="btn btn-link text-dark btn-sm ms-2"
                @click="$emit('first')"
                :disabled="isFirstPage">
            <span class="material-symbols-rounded align-middle">first_page</span>
        </button>
        <button class="btn btn-link text-dark btn-sm ms-2"
                @click="$emit('previous')"
                :disabled="isFirstPage">
            <span class="material-symbols-rounded align-middle">chevron_left</span>
        </button>
        <button class="btn btn-link text-dark btn-sm ms-2"
                @click="$emit('next')"
                :disabled="isLastPage">
            <span class="material-symbols-rounded align-middle">chevron_right</span>
        </button>
        <button class="btn btn-link text-dark btn-sm ms-2"
                @click="$emit('last')"
                :disabled="isLastPage">
            <span class="material-symbols-rounded align-middle">last_page</span>
        </button>
    </div>
</template>

<script>
import AppSelect from '@/components/form/AppSelect';

export default {
  props: ['currentPage', 'lastPage', 'isFirstPage', 'isLastPage', 'paginationOptions', 'limit'],
  components: {
    AppSelect,
  },
};
</script>
