<template>
    <div v-if="column.filterOptions.enabled">
        <div class="multiselect--form-control"
             v-if="column.filterOptions.filterDropdownItems">
            <AppSelect :options="column.filterOptions.filterDropdownItems"
                       :placeholder="column.label"
                       @input="onFilterChange($event, column)"
                       :value="selectValue(column)"/>
        </div>
        <AppDatePicker v-else-if="column.filterOptions.date"
                       :placeholder="column.label"
                       :value="filters[column.field]"
                       @input="onFilterChange($event, column)"
                       mode="range"
        />
        <input v-else
               type="text"
               class="form-control form-control-sm"
               :placeholder="column.label"
               @input="onFilterChange($event.target.value, column)"
               :value="filters[column.field]">
    </div>
</template>

<script>
import AppSelect from '@/components/form/AppSelect';
import AppDatePicker from '@/components/form/AppDatePicker';

export default {
  components: {
    AppSelect,
    AppDatePicker,
  },
  props: {
    column: {},
    filters: {},
  },
  methods: {
    onFilterChange(item, column) {
      let value;
      if (column.filterOptions.filterDropdownItems) {
        if (column.filterOptions.multiple) {
          value = item.map((i) => i.value);
        } else {
          value = item ? item.value : null;
        }
      } else if (column.filterOptions.date) {
        value = item.filter((i) => !!i).length > 0 ? item : null;
      } else {
        value = item;
      }

      const filters = {
        ...this.filters,
        [column.field]: value,
      };
      this.$emit('on-filter-change', filters);
    },
    selectValue(column) {
      if (!this.filters.hasOwnProperty(column.field)) {
        return null;
      }
      if (column.filterOptions.multiple) {
        return column.filterOptions.filterDropdownItems
          .filter((item) => this.filters[column.field].includes(item.value));
      }
      return column.filterOptions.filterDropdownItems
        .find((item) => item.value === this.filters[column.field]);
    },
  },
};
</script>
