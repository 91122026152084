<template>
    <div>
        <div class="row" :class="formSubscriberId ? 'justify-content-between' : 'justify-content-center'">
            <SubscribersList class="col-xl-10 pe-5" :class="formSubscriberId ? 'col-xl-7 col-lg-7' : 'col-12'"/>
            <transition name="slideLeftFade">
                <div class="col-lg-4 sidebar sidebar--right scrollbar" v-if="formSubscriberId">
                    <div class="d-flex justify-content-between align-items-center py-3">
                        <h5 class="mb-0">{{ isNew ? 'New subscriber' : `Subscriber` }}</h5>
                        <router-link :to="{ name: 'subscribers' }" class="btn">
                        <span class="material-symbols-rounded align-middle">
                            close
                        </span>
                        </router-link>
                    </div>
                    <SubscriberForm class="mb-4"/>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import SubscribersList from '@/components/subscribers/SubscribersList';
import SubscriberForm from '@/components/subscribers/SubscriberForm';

export default {
  components: {
    SubscriberForm,
    SubscribersList,
  },
  computed: {
    formSubscriberId() {
      return this.$route.params.id;
    },
    isNew() {
      return this.formSubscriberId === 'new';
    },
  },
};
</script>
